import cx from 'classnames';
import * as React from 'react';

type Props = {
  isActive: boolean;
  children: React.ReactNode;
  onClick: () => void;
  narrow?: boolean;
};

export const TabSelector: React.FC<Props> = ({ isActive, narrow, children, onClick }) => (
  <button
    className={cx(
      'mr-8 group inline-flex items-center px-2 py-4 border-b-2 font-medium leading-5 cursor-pointer whitespace-nowrap',
      isActive
        ? [
            'border-dojoyellow text-dojoyellow',
            'focus:outline-none focus:text-dojoyellow focus:border-dojoyellow',
          ]
        : [
            'border-transparent text-gray-500',
            'hover:text-gray-600 hover:border-gray-300',
            'focus:text-gray-600 focus:border-gray-300',
          ],
      narrow ? 'leading-6 px-0 py-1.5 font-bold' : ''
    )}
    onClick={onClick}
  >
    {children}
  </button>
);
